export enum Purpose {
  STORE_INFO_ON_DEVICE = 1,
  SELECT_BASIC_ADS = 2,
  CREATE_PERSONALISED_ADS_PROFILE = 3,
  SELECT_PERSONALISED_ADS = 4,
  CREATE_PERSONALISED_CONTENT_PROFILE = 5,
  SELECT_PERSONALISED_CONTENT = 6,
  MEASURE_AD_PERFORMANCE = 7,
  MEASURE_CONTENT_PERFORMANCE = 8,
  APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE = 9,
  DEVELOP_AND_IMPROVE_PRODUCTS = 10,
  USE_LIMITED_DATA_TO_SELECT_CONTENT = 11,
}

export const allowedPurposes: Purpose[] = [
  Purpose.STORE_INFO_ON_DEVICE,
  Purpose.SELECT_BASIC_ADS,
  Purpose.CREATE_PERSONALISED_ADS_PROFILE,
  Purpose.SELECT_PERSONALISED_ADS,
  Purpose.CREATE_PERSONALISED_CONTENT_PROFILE,
  Purpose.SELECT_PERSONALISED_CONTENT,
  Purpose.MEASURE_AD_PERFORMANCE,
  Purpose.MEASURE_CONTENT_PERFORMANCE,
  Purpose.APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE,
  Purpose.DEVELOP_AND_IMPROVE_PRODUCTS,
  Purpose.USE_LIMITED_DATA_TO_SELECT_CONTENT,
]
